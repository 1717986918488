import Vue from 'vue'
import VueI18n from 'vue-i18n'
import Cookies from 'js-cookie'
import tcLocale from './tc'
import enLocale from './en'
import jpLocale from './jp'
import krLocale from './kr'
import scLocale from './sc'


Vue.use(VueI18n)

const messages = {
  tc: {
    ...tcLocale,
  },
  en: {
    ...enLocale
  },
  jp: {
    ...jpLocale
  },
  kr: {
    ...krLocale
  },
  sc:{
    ...scLocale
  }
}

const mapBrowserLanguage = (lang) => {
  if (/^zh/.test(lang)) {
    return 'tc'
  } else if (/^ja/.test(lang)) {
    return "jp";
  } else if (/^ko/.test(lang)) {
    return "kr";
  } else {
    return "en";
  }
}

const defaultLocale = () => {
  //get cookies lang code
  let cookiesLang = Cookies.get("language");
  cookiesLang = messages[cookiesLang] ? cookiesLang : null;
  //get browser lang code
  let multLang = navigator.language || navigator.browserLanguage;
  multLang = mapBrowserLanguage(multLang) // mapping multi-lang code

  if (multLang === 'tc') {
    return cookiesLang || 'tc'
  } else {
    return cookiesLang || multLang || 'en'
  }
};



const i18n = new VueI18n({
  // set locale
  locale: defaultLocale(),
  // set locale messages
  messages,
  // silent i18n warning
  // 开发环境显示警告
  silentTranslationWarn: process.env.NODE_ENV === "production"
})

export default i18n
