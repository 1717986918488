// other blackboard content with tour page
const otherBlackBoardContent = [
  {
    name: "home.p10",
    phase: 1,
    contents: [
      {
        info: "tour.p10",
        date: "2023-07-04",
        href: "/tournament/page03",
        line: true,
      },
      {
        info: "news.p43",
        date: "2022-12-05",
        href: "/news/page11",
        line: true,
      },
      {
        info: "news.p27",
        date: "2022-09-30",
        href: "/news/page10",
        line: true,
      },
      {
        info: "news.p20",
        date: "2022-09-23",
        href: "/news/page09",
        line: true,
      },
      // {
      //   info: 'news.p7',
      //   date: '2022-06-30',
      //   href: '/news/page08',
      //   line: true
      // },
      // {
      //   info: 'tour.p3',
      //   date: '2022-06-23',
      //   href: '/tournament/page02',
      //   line: true
      // },
      // {
      //   info: '【イベント】一番街桜祭り、現在開催中！',
      //   date: '2022.03.22',
      //   href: '/news/page07',
      //   line: true
      // },
      // {
      //   info: '開発者からのご挨拶',
      //   date: '2022.01.25',
      //   href: '/news/page06',
      //   line: true
      // },
      // {
      //   info: '『アルテマ』にて、『麻雀一番街』のプレイレビューが掲載！',
      //   date: '2022.01.21',
      //   href: '/news/page05',
      //   line: true
      // }
    ],
  },
  {
    name: "home.p11",
    phase: 2,
    contents: [
      {
        info: "news.p27",
        date: "2022-09-30",
        href: "/news/page10",
        line: true,
      },
      {
        info: "開発者からのご挨拶",
        date: "2022-01-25",
        href: "/news/page06",
        line: true,
      },
      {
        info: "『アルテマ』にて、『麻雀一番街』のプレイレビューが掲載！",
        date: "2022-01-21",
        href: "/news/page05",
        line: true,
      },
      {
        info: "『麻雀一番街』配信開始！！",
        date: "2022-01-19",
        href: "/news/page04",
        line: true,
      },
      // {
      //   info: '瑞原明奈、『麻雀一番街』公式アンバサダーに就任！！',
      //   date: '2021-12-25',
      //   href: '/news/page03',
      //   line: true
      // }
    ],
  },
  {
    name: "home.p12",
    phase: 3,
    contents: [
      {
        info: "tour.p10",
        date: "2023-07-04",
        href: "/tournament/page03",
        line: true,
      },
      {
        info: "tour.p3",
        date: "2022-06-23",
        href: "/tournament/page02",
        line: true,
      },
      // {
      //   info: '『麻雀一番街』公式大会へようこそ！！',
      //   date: '2021-11-30',
      //   href: '/tournament/page01',
      //   line: true
      // },
    ],
  },
  {
    name: "home.p13",
    phase: 4,
    contents: [
      {
        info: "news.p43",
        date: "2022-12-05",
        href: "/news/page11",
        line: true,
      },
      {
        info: "news.p20",
        date: "2022-09-23",
        href: "/news/page09",
        line: true,
      },
      {
        info: "news.p7",
        date: "2022-06-30",
        href: "/news/page08",
        line: true,
      },
      {
        info: "【イベント】一番街桜祭り、現在開催中！",
        date: "2022-03-22",
        href: "/news/page07",
        line: true,
      },
    ],
  },
];
// kr don't display tour content
const krBlackBoardContent = [
  {
    name: "home.p10",
    phase: 1,
    contents: [
      {
        info: "[아이템 추가지급 이벤트]",
        date: "2024-05-31",
        href: "/news/page13",
        line: true,
      },
      {
        info: "[STOVE 10% 캐시백 이벤트]",
        date: "2024-05-31",
        href: "/news/page12",
        line: true,
      },
      {
        info: "news.p27",
        date: "2022-09-30",
        href: "/news/page10",
        line: true,
      },
      {
        info: "news.p20",
        date: "2022-09-23",
        href: "/news/page09",
        line: true,
      },
      // {
      //   info: 'news.p7',
      //   date: '2022-06-30',
      //   href: '/news/page08',
      //   line: true
      // },
      // {
      //   info: '【イベント】一番街桜祭り、現在開催中！',
      //   date: '2022.03.22',
      //   href: '/news/page07',
      //   line: true
      // },
      // {
      //   info: '開発者からのご挨拶',
      //   date: '2022.01.25',
      //   href: '/news/page06',
      //   line: true
      // },
      // {
      //   info: '『アルテマ』にて、『麻雀一番街』のプレイレビューが掲載！',
      //   date: '2022.01.21',
      //   href: '/news/page05',
      //   line: true
      // },
      // {
      //   info: '『麻雀一番街』配信開始！！',
      //   date: '2022-01-19',
      //   href: '/news/page04',
      //   line: true
      // },
      // {
      //   info: '瑞原明奈、『麻雀一番街』公式アンバサダーに就任！！',
      //   date: '2021-12-25',
      //   href: '/news/page03',
      //   line: true
      // },
      // {
      //   info: '松本吉弘、『麻雀一番街』公式アンバサダーに就任！！',
      //   date: '2021-12-13',
      //   href: '/news/page02',
      //   line: true
      // },
      // {
      //   info: '『麻雀一番街』の事前登録受付がスタート！！',
      //   date: '2021-11-30',
      //   href: '/news/page01',
      //   line: true
      // },
      // {
      //   info: '『麻雀一番街』公式大会へようこそ！！',
      //   date: '2021-11-30',
      //   href: '/tournament/page01',
      //   line: true
      // }
    ],
  },
  {
    name: "home.p11",
    phase: 2,
    contents: [
      {
        info: "[아이템 추가지급 이벤트]",
        date: "2024-05-31",
        href: "/news/page13",
        line: true,
      },
      {
        info: "[STOVE 10% 캐시백 이벤트]",
        date: "2024-05-31",
        href: "/news/page12",
        line: true,
      },
      {
        info: "news.p27",
        date: "2022-09-30",
        href: "/news/page10",
        line: true,
      },
      {
        info: "開発者からのご挨拶",
        date: "2022-01-25",
        href: "/news/page06",
        line: true,
      },
      // {
      //   info: '『アルテマ』にて、『麻雀一番街』のプレイレビューが掲載！',
      //   date: '2022.01.21',
      //   href: '/news/page05',
      //   line: true
      // },
      // {
      //   info: '『麻雀一番街』配信開始！！',
      //   date: '2022-01-19',
      //   href: '/news/page04',
      //   line: true
      // },
      // {
      //   info: '瑞原明奈、『麻雀一番街』公式アンバサダーに就任！！',
      //   date: '2021-12-25',
      //   href: '/news/page03',
      //   line: true
      // },
      // {
      //   info: '松本吉弘、『麻雀一番街』公式アンバサダーに就任！！',
      //   date: '2021-12-13',
      //   href: '/news/page02',
      //   line: true
      // },
      // {
      //   info: '『麻雀一番街』の事前登録受付がスタート！！',
      //   date: '2021-11-30',
      //   href: '/news/page01',
      //   line: true
      // }
    ],
  },
  {
    name: "home.p12",
    phase: 3,
    contents: [
      {
        info: "『麻雀一番街』公式大会へようこそ！！",
        date: "2021-11-30",
        href: "/tournament/page01",
        line: true,
      },
    ],
  },
  {
    name: "home.p13",
    phase: 4,
    contents: [
      {
        info: "news.p20",
        date: "2022-09-23",
        href: "/news/page09",
        line: true,
      },
      {
        info: "news.p7",
        date: "2022-06-30",
        href: "/news/page08",
        line: true,
      },
      {
        info: "【イベント】一番街桜祭り、現在開催中！",
        date: "2022-03-22",
        href: "/news/page07",
        line: true,
      },
    ],
  },
];

export const blackboardContent = (locale) => {
  console.log("locale:", locale);
  return locale === "kr" ? krBlackBoardContent : otherBlackBoardContent;
};
