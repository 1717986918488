<template>
  <v-menu offset-y bottom right max-width="138">
    <template #activator="{ on, attrs }">
      <div
        class="lang row reverse justify-center text-center"
        v-bind="attrs"
        v-on="on"
      >
        <img src="/static/img/common/lang-icon2.png" />
        <p>Language</p>
      </div>
    </template>
    <v-list class="listLang">
      <v-list-item
        v-for="(item, index) in languages"
        :key="index"
        @click="handleSetLanguage(item.content)"
        class="langMenu text-center"
        :class="{ hightlight: language == item.content }"
      >
        <v-list-item-title style="cursor: pointer">
          {{ item.title }}
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>
<script>
import Cookies from "js-cookie";
export default {
  data() {
    return {
      languages: [
        { title: "日本語", content: "jp" },
        { title: "English", content: "en" },
        { title: "한국어", content: "kr" },
        { title: "繁體中文", content: "tc" },
      ],
    };
  },
  computed: {
    language() {
      return this.$i18n.locale;
    },
  },
  created() {
    // 判断是否为简体中文，如果是则切换为繁体中文
    if (Cookies.get("language") === "sc") {
      this.handleSetLanguage("tc");
    }
  },
  methods: {
    handleSetLanguage(lang) {
      Cookies.set("language", lang, { expires: 30 });
      this.$store.dispatch("setLanguage", lang);
      this.$i18n.locale = lang;
      this.$emit("changFlag");
    },
  },
  watch: {
    "$i18n.locale"() {
      //  根据语言切换对应的显示页面
      //  当前隐私条款 / 利用规定 页面为日语时，根据语言的切换，显示为英语；反之
      if (this.$route.path === "/privacyPolicy" && this.$i18n.locale !== "jp") {
        this.$router.replace("/en/privacyPolicy");
      } else if (
        this.$route.path === "/en/privacyPolicy" &&
        this.$i18n.locale === "jp"
      ) {
        this.$router.replace("/privacyPolicy");
      } else if (
        this.$route.path === "/termsofService" &&
        this.$i18n.locale !== "jp"
      ) {
        this.$router.replace("/en/termsofService");
      } else if (
        this.$route.path === "/en/termsofService" &&
        this.$i18n.locale === "jp"
      ) {
        this.$router.replace("/termsofService");
      } else if (
        this.$route.path === "/tournament/page02" &&
        this.$i18n.locale === "kr"
      ) {
        location.href = "/tournament/page01";
        // this.$router.replace("/tournament/page01")
      } else if (
        this.$route.path === "/tournament/page01" &&
        this.$i18n.locale !== "kr"
      ) {
        location.href = "/tournament/page02";
        // this.$router.replace("/tournament/page02")
      } else {
        return;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.lang {
  height: 50px;
  font-size: 16px;
  @media only screen and (max-width: 1200px) {
    font-size: 14px;
  }
  @media only screen and (max-width: 450px) {
    font-size: 13px;
  }
  &:hover {
    background-color: #15192c;
  }
  p {
    line-height: 50px;
    color: #7d8dfd;
  }
  img {
    margin: 15px 5px 0 0;
    vertical-align: middle;
    width: 20px;
    height: 20px;
  }
}
.v-menu__content {
  box-shadow: none;
  border-radius: 10px;
  .theme--light {
    background-color: #485493;
    border-radius: 10px;
  }
  .listLang {
    border: 1px solid #000000;
    border-radius: 10px;
    padding: 0;
    float: right;
    @media only screen and (max-width: 950px) {
      margin-top: 2px;
    }
  }
  .langMenu {
    .v-list-item__title {
      color: #ffffff;
      line-height: 1.5;
    }
    &:hover {
      background-color: #111624;
    }
  }
  .hightlight {
    background-color: #111624;
    pointer-events: none;
  }
}
@media only screen and (max-width: 960px) {
  .lang {
    width: 145px;
    margin-top: 12px;
    img {
      margin: 15px 3px 0 0;
    }
  }
}
</style>
