import Vue from "vue";
import Router from "vue-router";
import Layout from "./pages/layout.vue";
import Home from "./pages/home.vue";
import page404 from "@/components/404.vue";
import { getActivityList } from "@/api/common";
import { getWebSiteInfo } from "@/api/common";
import store from "./store";
Vue.use(Router);

//解决路由跳转 从/en/privacyPolicy 跳转到/privacyPolicy 报错问题
const originalPush = Router.prototype.replace;
Router.prototype.replace = function replace(location) {
  return originalPush.call(this, location).catch((err) => err);
};

let router = new Router({
  mode: "history",
  base: "/",
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
  routes: [
    {
      path: "/",
      name: "/",
      component: Layout,
      redirect: { name: "home" },
      children: [
        {
          path: "home",
          name: "home",
          component: Home,
          meta: { keepAlive: true, noresposive: true },
        },

        {
          path: "characters",
          name: "characters",
          component: () => import("@/pages/characters.vue"),
          meta: { keepAlive: true, noresposive: true },
        },
        {
          path: "characters/:id",
          name: "characterDetail",
          component: () => import("@/pages/characterDetail.vue"),
          meta: { keepAlive: true, noresposive: true },
        },

        {
          path: "news",
          name: "news",
          component: () => import("@/pages/newsPage/news.vue"),
          meta: { keepAlive: true, noresposive: true },
        },
        {
          path: "news/:id",
          name: "newsDetail",
          component: () => import("@/pages/newsPage/newsDetail.vue"),
          meta: { keepAlive: false, noresposive: true },
        },

        {
          path: "tournament",
          name: "tournament",
          component: () => import("@/pages/tournamentPage/tournament.vue"),
          meta: { keepAlive: true, noresposive: true },
        },
        {
          path: "tournament/:id",
          name: "tournamentDetail",
          component: () => import("@/pages/tournamentPage/tournamentDetail.vue"),
          meta: { keepAlive: false, noresposive: true },
        },

        {
          path: "special",
          name: "special",
          component: () => import("@/pages/specialPage/special.vue"),
          meta: { keepAlive: true, noresposive: true },
        },
        {
          path: "special/:id",
          name: "specialDetail",
          component: () => import("@/pages/specialPage/specialDetail.vue"),
          meta: { noresposive: true },
        },

        {
          path: "verify",
          name: "verify",
          component: () => import("@/pages/verifyPage/verify.vue"),
          meta: { keepAlive: true, noresposive: true },
        },

        // { path: 'preRegister', name: 'preRegister', component: () => import('@/pages/preRegister.vue'), meta: { keepAlive: true, noresposive: true } },
        {
          path: "about",
          name: "about",
          component: () => import("@/pages/about.vue"),
          meta: { keepAlive: true, noresposive: true },
        },

        {
          path: "privacyPolicy",
          name: "privacyPolicy",
          component: () => import("@/pages/PrivacyPolicy.vue"),
          meta: { keepAlive: true, noresposive: true },
        },

        // multi-lang policy pages
        {
          path: "/en/privacyPolicy",
          name: "privacyPolicyEn",
          component: () => import("@/pages/privacy/PrivacyPolicyEn.vue"),
          meta: { keepAlive: true, noresposive: true },
        },

        {
          path: "termsofService",
          name: "termsofService",
          component: () => import("@/pages/TermsofService.vue"),
          meta: { keepAlive: true, noresposive: true },
        },

        {
          path: "/en/termsofService",
          name: "termsofServiceEn",
          component: () => import("@/pages/termsOfService/TermsofServiceEn.vue"),
          meta: { keepAlive: true, noresposive: true },
        },

        {
          path: "disclaimer",
          name: "disclaimer",
          component: () => import("@/pages/disclaimer.vue"),
          meta: { keepAlive: true, noresposive: true },
        },
        {
          path: "deliveryGuidelines",
          name: "deliveryGuidelines",
          component: () => import("@/pages/deliveryGuidelines.vue"),
          meta: { keepAlive: true, noresposive: true },
        },

        {
          path: "legal1",
          name: "legal1",
          component: () => import("@/pages/legal1.vue"),
          meta: { keepAlive: true, noresposive: true },
        },
        {
          path: "legal2",
          name: "legal2",
          component: () => import("@/pages/legal2.vue"),
          meta: { keepAlive: true, noresposive: true },
        },
        {
          path: "legal3",
          name: "legal3",
          component: () => import("@/pages/legal3.vue"),
          meta: { keepAlive: true, noresposive: true },
        },

        {
          path: "streamer",
          name: "streamer",
          component: () => import("@/pages/streamer.vue"),
          meta: { keepAlive: true, noresposive: true },
        },
        {
          path: "/gachaProbability",
          name: "gachaProbability",
          // component: () => import("@/pages/gachaProbability.vue"),
          component: () => import("@/pages/gachaProbabilityNew.vue"),
        },
        // {
        //   path: "/gachaProbabilityNew",
        //   name: "gachaProbabilityNew",
        //   component: () => import("@/pages/gachaProbabilityNew.vue"),
        // },
      ],
    },
    {
      path: "/riichicitystore",
      name: "riichicitystore",
      component: () => import("@/pages/Riichicitystore.vue"),
    },
    {
      path: "/sgtest",
      name: "sgtest",
      // redirect: "/home",
      component: () => import("@/pages/sgtest/Sgtest.vue"),
      beforeEnter: async (to, from, next) => {
        await store.dispatch("getActivityList");
        // 清除旧的meta标签
        Array.from(document.querySelectorAll('head > meta[property^="og:"]')).forEach((el) => el.remove());

        // 创建新的meta标签
        const metaTags = [
          { property: "fb:app_id", content: "1140319583771256" },
          { property: "og:url", content: "https://www.mahjong-jp.com/sgtest" },
          { property: "og:title", content: "[Riichi City × STEINS;GATE] Excl. Lab Mem Aptitude Test" },
          {
            property: "og:description",
            content: `[Riichi City × STEINS;GATE Collab] Limited-time event is live! Ready to become a proud member of the Future Gadget Laboratory and battle against the sinister Organization with Hououin Kyouma? Take the Lab Mem Aptitude Test, prove yourself as a true warrior, and unleash your hidden potential now!`,
          },
          {
            property: "og:image",
            content: "https://www.mahjong-jp.com/static/img/sgtest/favicon.png",
          },
          {
            property: "keywords",
            content:
              "Riichi City, STEINS;GATE, STEINS;GATE, Future Gadget Laboratory, Lab Mem, Hououin Kyouma, Okabe Rintaro, Aptitude Test",
          },
        ];

        metaTags.forEach((metaTag) => {
          const tag = document.createElement("meta");
          tag.setAttribute("property", metaTag.property);
          tag.setAttribute("content", metaTag.content);
          document.head.appendChild(tag);
        });

        // 清除旧的favicon
        Array.from(document.querySelectorAll('link[rel="icon"]')).forEach((el) => el.remove());
        // 创建新的favicon
        const favicon = document.createElement("link");
        favicon.setAttribute("rel", "icon");
        favicon.setAttribute("type", "image/png");
        favicon.setAttribute("href", "/static/img/sgtest/favicon.png");
        document.head.appendChild(favicon);
        // 设置页面标题
        // document.title = "[Riichi City × STEINS;GATE] Excl. Lab Mem Aptitude Test";
        next();
      },
    },
    {
      path: "/drtest",
      name: "drtest",
      component: () => import("@/pages/survey/Survey.vue"),
      beforeEnter: async (to, from, next) => {
        /* 活动时间 start*/
        //  2024-06-06 活动未开始时密码输入
        await store.dispatch("getActivityList");
        // const activityList = store.state.activityList;
        // if (!activityList || activityList.length === 0) {
        //   next("/");
        //   return;
        // }
        // const serverTime = store.state.serverTime;
        // const activity = activityList.find((item) => item.activityType === 241);
        // const { receiveStartTime } = activity;
        // if (!activity) {
        //   next("/");
        //   return;
        // }
        /* 活动时间 end */

        // 清除旧的meta标签
        /* 活动结束，清除meta与标题 start */
        /*  Array.from(
          document.querySelectorAll('head > meta[property^="og:"]')
        ).forEach((el) => el.remove());

        // 创建新的meta标签
        const metaTags = [
          { property: "fb:app_id", content: "1140319583771256" },
          { property: "og:url", content: "https://www.mahjong-jp.com/drtest" },
          { property: "og:title", content: "麻雀一番街 『超高校級診断』" },
          {
            property: "og:description",
            content: `「超高校級」の才能を発掘するため、我々——希望ヶ峰学園は、「超高校級診断」を開発した。是非、参加をお願いしたい。`,
          },
          {
            property: "og:image",
            content:
              "https://www.mahjong-jp.com/static/img/survey/favicon/favicon.png",
          },
          {
            property: "keywords",
            content:
              "麻雀一番街,ダンガンロンパ,超高校級診断,超高校級の,希望ヶ峰学園",
          },
        ];

        metaTags.forEach((metaTag) => {
          const tag = document.createElement("meta");
          tag.setAttribute("property", metaTag.property);
          tag.setAttribute("content", metaTag.content);
          document.head.appendChild(tag);
        });

        // 清除旧的favicon
        Array.from(document.querySelectorAll('link[rel="icon"]')).forEach(
          (el) => el.remove()
        );
        // 创建新的favicon
        const favicon = document.createElement("link");
        favicon.setAttribute("rel", "icon");
        favicon.setAttribute("type", "image/png");
        favicon.setAttribute("href", "/static/img/survey/favicon/favicon.png");
        document.head.appendChild(favicon);
        // 设置页面标题
        document.title = "『麻雀一番街×ダンガンロンパコラボ』<超高校級診断>"; */
        /* 活动结束，清除meta与标题 end */

        next();
      },
    },
    {
      path: "/test",
      name: "test",
      component: () => import("@/pages/test.vue"),
    },
    // { path: '/tc/privacyPolicy', name: 'privacyPolicyTc', component: () => import('@/pages/privacy/PrivacyPolicyTc.vue'), meta: { keepAlive: true, noresposive: true } },
    // { path: '/tc/termsofService', name: 'termsofServiceTc', component: () => import('@/pages/termsOfService/TermsofServiceTc.vue'), meta: { keepAlive: true, noresposive: true } },
    // 跳转使用
    {
      path: "/zh/linegroup",
      name: "linegroup",
      beforeEnter: () => {
        // 无效代码，修改linegroup跳转地址在file:public/static/js/tojump.js
        // window.location.href = "https://line.me/R/ti/g/O0vq5z25lt";
      },
    },
    {
      path: "/redirect/ad/18f1shgz",
      name: "redirectAd",
      beforeEnter: () => {
        window.location.href = "https://app.adjust.com/18f1shgz?campaign=Keyword";
      },
    },
    {
      path: "*",
      name: "NoResult",
      redirect: "/",
    },
  ],
});

export default router;
