import i18n from "@/locales";
export const isMobile = () => {
  return !!navigator.userAgent.match(
    /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
  );
};

export const isClient = typeof window !== "undefined";

export const isIOS = /* #__PURE__ */ isClient && window?.navigator?.userAgent && /iP(ad|hone|od)/.test(window.navigator.userAgent);

export const translate = (text, obj) => {
  const reg = /^[a-zA-Z_]+\.p\d+$/; // 匹配表格中的多语言字段
  if (reg.test(text)) {
    return i18n.t(text, obj);
  }
  return text;
};
export const deepClone = (obj) => {
  if (obj === null || typeof obj !== "object") return obj;
  const newObj = Array.isArray(obj) ? [] : {};
  for (const key in obj) {
    newObj[key] = deepClone(obj[key]);
  }
  return newObj;
};

export const getElContentSize = (el) => {
  const { width, height, paddingLeft, paddingRight, paddingTop, paddingBottom } = window.getComputedStyle(el);
  return {
    width: parseFloat(width) - parseFloat(paddingLeft) - parseFloat(paddingRight),
    height: parseFloat(height) - parseFloat(paddingTop) - parseFloat(paddingBottom),
  };
};
