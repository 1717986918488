<template>
  <div class="header">
    <v-app-bar color="#15192c" height="80px" dark class="text-center">
      <v-row class="ma-0">
        <v-col
          cols="2"
          align-self="center"
          class="row reverse justify-center d-flex bp400"
        >
          <a class="pt-2" href="/"
            ><img
              src="/static/img/common/happyWoodFinal.png?v=20220114"
              class="logo"
              width="100%"
          /></a>
        </v-col>
        <v-col
          v-if="refresh"
          cols="8"
          align-self="end"
          class="tabsList hidden-sm-and-down"
        >
          <v-tabs
            background-color="transparent"
            class="v-tabs row reverse justify-center"
            align-with-title
            right
            :show-arrows="true"
          >
            <v-tabs-slider v-show="isShowTabSlider" color="#ffe84b" />
            <v-tab
              ref="vTabRef"
              class="header_selected"
              :class="{ menu_red_dot: isShowRedDot(item.to) }"
              v-for="(item, i) in tabsName"
              :key="i"
              :to="item.to"
              @click="menuLight(item)"
            >
              {{ item.title }}
            </v-tab>
          </v-tabs>
        </v-col>
        <v-col cols="2" class="header-end hidden-sm-and-down justify-end pt-10">
          <v-list-item>
            <lang-select @changFlag="changFlag($event)" />
            <v-list-item-title class="aboutUs row reverse justify-center">
              <router-link to="/about" class="text-decoration-none">
                {{ $t("home.p9") }}</router-link
              >
            </v-list-item-title>
          </v-list-item>
        </v-col>
        <!-- mobile section start -->
        <v-col v-if="refresh" cols="auto" class="hidden-md-and-up ml-auto">
          <v-list-item>
            <lang-select />
            <v-menu offset-y bottom max-width="120">
              <template #activator="{ on, attrs }">
                <div
                  class="langMobile row reverse justify-center text-center"
                  v-bind="attrs"
                  v-on="on"
                >
                  <p>
                    {{ $t("home.p25") }}
                  </p>
                </div>
              </template>
              <v-list class="listPage text-center">
                <v-list-item
                  class="mb-0"
                  :class="{ menu_red_dot: isShowRedDot(item.to) }"
                  v-for="(item, index) in molTabsName"
                  :key="index"
                >
                  <v-list-item-title @click="menuLight(item)">
                    <router-link
                      class="text"
                      :to="item.to"
                      :class="$route.path === item.to ? 'active' : ''"
                      >{{ item.title }}</router-link
                    >
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-list-item>
        </v-col>
        <!-- mobile section end -->
      </v-row>
    </v-app-bar>
  </div>
</template>

<script>
import LangSelect from "./LangSelect.vue";

export default {
  components: { LangSelect },
  data() {
    return {
      clipped: false,
      drawer: false,
      dialog: false,
      refresh: true,
      isClicked: false,
    };
  },
  computed: {
    tabsName() {
      return [
        { title: this.$t("home.p2"), to: "/home" },
        { title: this.$t("home.p3"), to: "/characters" },
        { title: this.$t("home.p32"), to: "/riichicitystore" },
        { title: this.$t("home.p4"), to: "/news" },
        { title: this.$t("home.p5"), to: "/tournament" },
        { title: this.$t("home.p6"), to: "/special" },
        { title: this.$t("home.p7"), to: "/verify" },
      ];
    },
    molTabsName() {
      return this.tabsName;
      /*  return [
        ...this.tabsName.slice(0, 5),
        { title: this.$t("home.p31"), to: "/riichicitystore" },
      ]; */
    },
    isShowTabSlider() {
      return this.tabsName.find((item) => {
        return (
          item.to === this.$route.path ||
          this.$route.path.includes(item.to + "/")
        );
      });
    },
  },
  mounted() {
    this.loadFont();
    this.getIsClicked();
  },
  methods: {
    // 判断字体是否加载完成,加载完成后再刷新显示tabbar
    loadFont() {
      const values = document.fonts.values();
      let item = values.next();
      while (!item.done) {
        let fontFace = item.value;
        if (fontFace.family === "Source Han Sans") {
          if (fontFace.status === "loaded") {
            this.changFlag();
          } else {
            fontFace.loaded.then(() => {
              this.changFlag();
            });
          }
          break;
        }
        item = values.next();
      }
    },
    showPopup() {
      this.drawer = false;
    },
    changFlag() {
      // 语言切换时，将tabbar隐藏
      this.refresh = false;
      // 随后将tabbar显示，确保导航滑块长度适配
      this.$nextTick(() => {
        this.refresh = true;
      });
    },
    menuLight(item) {
      if (item.to === "/riichicitystore") {
        this.setIsClicked();
      }
    },
    setIsClicked() {
      this.isClicked = true;
      window.localStorage.setItem("clicked", JSON.stringify(true));
    },
    // 菜单红点判断
    getIsClicked() {
      this.isClicked = JSON.parse(
        window.localStorage.getItem("clicked") || "false"
      );
    },
    isShowRedDot(to) {
      return to === "/riichicitystore" && !this.isClicked;
    },
  },
};
</script>

<style lang="scss" scoped>
.bp400 {
  max-width: 150px;
  .logo {
    margin-left: 20px;
    width: 100%;
    image-rendering: -webkit-optimize-contrast;
    image-rendering: -moz-crisp-edges;
    -ms-interpolation-mode: nearest-neighbor;
  }
}
.tabsList {
  font-weight: 500;
  position: relative;
  left: 8%;
  margin: 0 0px 10px 20px;
  z-index: 1;
  .menu_red_dot {
    position: relative;
    &:after {
      content: "";
      position: absolute;
      width: 10px;
      height: 10px;
      background-color: #ff0000;
      border-radius: 50%;
      top: 6px;
      right: 6px;
    }
  }
}
.header_selected {
  // font-weight: 500;
  line-height: 60px;
  font-size: 18px;
  font-weight: bold;
  margin-left: 20px;
  &:hover {
    // margin-bottom: -5px;
    background-color: #7d8dfd;
    color: #ffe84b;
  }
}
.header-end {
  text-align: end;
  margin: 0 0 0 5%;
}
.lang {
  height: 50px;
  width: 100%;
  font-size: 16px;
  &:hover {
    background-color: #15192c;
  }
  p {
    line-height: 50px;
    color: #7d8dfd;
  }
}
.langMobile {
  height: 40px;
  width: 100%;
  font-size: 16px;
  margin-left: 0px;
  p {
    line-height: 65px;
    color: #7d8dfd;
  }
}
.v-menu__content {
  box-shadow: none;
  border-radius: 10px;
  .theme--light {
    background-color: #485493;
    border-radius: 10px;
  }
  .listPage {
    padding: 0;
    border: 1px solid #000000;
    border-radius: 10px;
    margin-top: 20px;
    .setAsh {
      opacity: 0.2;
      cursor: not-allowed !important;
      pointer-events: none;
      &:hover {
        background-color: #15192c;
      }
    }
    .menu_red_dot {
      position: relative;
      &:after {
        content: "";
        position: absolute;
        width: 10px;
        height: 10px;
        min-height: 10px;
        line-height: 10px;
        background-color: #ff0000;
        border-radius: 50%;
        top: 6px;
        right: 6px;
      }
    }
  }
}
.aboutUs {
  a {
    color: #7d8dfd;
  }
}

.text {
  text-decoration: none;
  color: white;
  &:hover {
    color: #ffe84b !important;
  }
}
.active {
  color: #ffe84b;
}
@media only screen and (max-width: 1300px) {
  .header_selected {
    margin-left: 0px;
    font-size: 15px;
  }
  .header-end {
    padding-left: 0;
    padding-right: 0;
    font-size: 14px;
    margin: 0 -5% 0 0%;
  }
  .aboutUs {
    margin-left: 20px;
    justify-content: flex-end;
  }
  .tabsList {
    left: 0%;
  }
}
@media only screen and (max-width: 1150px) {
  .bp400 {
    min-width: 120px;
  }
  .header_selected {
    margin-left: 0px;
  }
}
@media only screen and (max-width: 500px) {
  .bp400 {
    padding-left: 0px !important;
    padding-right: 0px !important;
    min-width: 120px;
    .logo {
      margin-left: 20px;
      width: 100%;
    }
  }
}
</style>
