// jp footer list
const footerJPList = [
  {
    title: "ヘルプ",
    href: "/about",
  },
  {
    title: "プライバシーポリシー",
    href: "/privacyPolicy",
  },
  {
    title: "利用規約",
    href: "/termsofService",
  },
  {
    title: "免責事項",
    href: "/disclaimer",
  },
  {
    title: "配信ガイドライン",
    href: "/deliveryGuidelines",
  },
  {
    title: "特定商取引法に基づく表記",
    href: "/legal1",
  },
  {
    title: "資金決済法に基づく表記",
    href: "/legal2",
  },
  {
    title: "不正行為について",
    href: "/legal3",
  },
];
// other lang footer list
const footerOtherList = [
  {
    title: "home.p9",
    href: "/about",
  },
  {
    title: "home.p21",
    href: "/en/privacyPolicy",
  },
  {
    title: "home.p22",
    href: "/en/termsofService",
  },
  {
    title: "home.p23",
    href: "/disclaimer",
  },
  {
    title: "home.p24",
    href: "/legal3",
  },
];

const gachaProbability = {
  title: "제공 비율",
  href: "/gachaProbability",
};

export const footerPolicyList = (locale) => {
  if (locale === "jp") {
    return footerJPList;
  } else if (locale === "kr") {
    return [...footerOtherList, gachaProbability];
  } else {
    return footerOtherList;
  }
};
