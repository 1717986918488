import Vue from "vue";
import Vuex from "vuex";
import Cookies from "js-cookie";
import i18n from "./locales";
import { getNewList } from "@/api/news";
import { getActivityList } from "@/api/common";
Vue.use(Vuex);

// const $LANGUAGE = (navigator.language || navigator.browserLanguage).toLowerCase()
// console.log($LANGUAGE)

export default new Vuex.Store({
  state: {
    tab: "",
    // language: Cookies.get('language') || 'en',
    language: i18n.locale || "en",
    downLink: {},
    defaultDownLink: {
      APK: "",
      Apple: "https://apps.apple.com/app/id1578816591",
      Google:
        "https://play.google.com/store/apps/details?id=com.riichicity.happywoods",
      Linux: "",
      Mac: "",
      Steam: "https://store.steampowered.com/app/1954420/_/",
      Windows: "",
      Stove: "https://store.onstove.com/ko/games/3412",
    },
    timeStamp: 0,
    allNewsList: [],
    newsTagMap: {
      1: "news.p3", //游戏公告
      2: "news.p4", //维护咨询
      3: "news.p5", //更新情报
      4: "news.p6", //活动咨询
      5: "tour.p2", //比赛
      6: "special.p2", //福利
    },
    activityList: [],
    serverTime: 0,
  },
  getters: {
    language: (state) => state.language,
  },
  mutations: {
    changeTab(state, tab) {
      state.tab = tab;
    },
    SET_LANGUAGE: (state, language) => {
      state.language = language;
      Cookies.set("language", language, { expires: 30 });
    },
    setDownload(state, downLink) {
      const { Apple, Google, Steam, Stove } = state.defaultDownLink;
      state.downLink = {
        ...downLink,
        Apple: downLink.Apple || Apple,
        Google: downLink.Google || Google,
        Steam: downLink.Steam || Steam,
        Stove: downLink.Stove || Stove,
      };
    },
    setTimeStamp(state, timeStamp) {
      state.timeStamp = timeStamp;
    },
    setNewsList(state, allNewsList) {
      state.allNewsList = allNewsList || [];
    },
  },
  actions: {
    setLanguage({ commit }, language) {
      commit("SET_LANGUAGE", language);
    },
    async getNewAllList({ commit }, payload) {
      const res = await getNewList({
        limit: 998,
        skip: 1,
        show_home: 0,
        token: payload.$route.query.token || undefined,
      });
      if (res.data.code !== 0) return;
      commit("setNewsList", res.data.data);
    },
    async getActivityList({ commit, state }) {
      try {
        if (state.activityList && state.activityList.length > 0) {
          return;
        }
        const res = await getActivityList();
        if (res.data.code !== 0) return;
        state.activityList = res.data.data.list;
        state.serverTime = res.data.data.serverTime;
      } catch (e) {
        console.log(e);
      }
    },
  },
});
