<template>
  <v-footer dark color="#000000" class="footer">
    <v-container class="">
      <v-row no-gutters class="mx--sm-10 mx-5">
        <v-col cols="12" class="">
          <v-row class="mr-0 mr-sm-0 text-center">
            <v-col
              v-for="(link, i) in links"
              :key="i.title"
              cols="12"
              sm="6"
              md="3"
            >
              <router-link
                :to="link.href"
                class="link_style my-2 d-block white--text font-weight-bold text-decoration-none"
              >
                {{ translate(link.title) }}
              </router-link>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" class="copyright mt-sm-2 mt-0">
          <p style="color: rgb(140, 140, 140)">
            Copyright © 麻雀一番街 All Rights Reserved.
          </p>
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>
<script>
import { footerPolicyList } from "../configs/footer-content";
import { translate } from "@/utils/utils";
export default {
  data() {
    return {
      clipped: false,
    };
  },
  computed: {
    links() {
      return footerPolicyList(this.$store.state.language);
    },
  },
  methods: {
    translate,
  },
};
</script>
<style lang="scss">
.footer {
  padding: 100px 0 !important;
  .link_style {
    font-size: 15px;
    white-space: nowrap;
  }
  .copyright {
    font-size: 12px;
    margin-left: 100px;
  }
}
@media only screen and (max-width: 980px) {
  .footer {
    padding: 20px 0 !important;
    .copyright {
      font-size: 10px;
      text-align: center;
      margin-top: 10px;
      margin-left: 0;
    }
  }
}
</style>
