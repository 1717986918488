// multi-lang in community page
export const socailMedia= {
  jp: [
    {
      title: "Twitter",
      link:"https://twitter.com/intent/follow?screen_name=RiichiCity_JP", 
      path: "/static/img/springFrame/twitter.png"
    },
    {
      title: "YouTube",
      link:"https://www.youtube.com/channel/UC3fBAXW5VNK4JLvOfoYHTAQ/?sub_confirmation=1", 
      path: "/static/img/springFrame/youtube.png"
    },
    {
      title: "Discord",
      link:"https://discord.gg/5z3Nw2rPNx", 
      path: "/static/img/springFrame/discord.png"
    }
  ],
  en: [
    {
      title: "Facebook",
      link:"https://www.facebook.com/Riichi-City-107331518266065", 
      path: "/static/img/springFrame/facebook.png"
    },
    {
      title: "Discord",
      link:"https://discord.gg/5z3Nw2rPNx", 
      path: "/static/img/springFrame/discord.png"
    }
  ],
  kr: [
    {
      title: "Twitter",
      link:"https://twitter.com/intent/follow?screen_name=RiichiCity_KR", 
      path: "/static/img/springFrame/twitter.png"
    },
    {
      title: "Discord",
      link:"https://discord.gg/5z3Nw2rPNx", 
      path: "/static/img/springFrame/discord.png"
    }
  ],
  tc: [
    {
      title: "Line",
      link:"http://www.mahjong-jp.com/zh/linegroup", 
      path: "/static/img/springFrame/line.png"
    },
    {
      title: "Facebook",
      link:"https://www.facebook.com/RiichiCity", 
      path: "/static/img/springFrame/facebook.png"
    },
    {
      title: "Discord",
      link:"https://discord.gg/5z3Nw2rPNx", 
      path: "/static/img/springFrame/discord.png"
    }
  ]
}