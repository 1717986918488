import { render, staticRenderFns } from "./StoryBackground.vue?vue&type=template&id=2949fac1&scoped=true&"
var script = {}
import style0 from "./StoryBackground.vue?vue&type=style&index=0&id=2949fac1&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2949fac1",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VLazy } from 'vuetify/lib/components/VLazy';
installComponents(component, {VLazy})
