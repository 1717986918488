<template>
  <div class="TopBanner">
    <div class="topic">
      <img
        class="hidden-sm-and-down"
        :src="`/static/img/home/top/topicSentence_${$i18n.locale}.png?t=20220620`"
      />
      <img
        class="hidden-md-and-up mobileTopic"
        :src="`/static/img/home/top/mobileTopic_${$i18n.locale}.png?t=20220620`"
      />
    </div>
    <div class="downloadButton">
      <v-container>
        <div class="list hidden-sm-and-down">
          <img
            class="buttonBg"
            src="/static/img/home/top/frame_bg.png?v=20221028"
            alt=""
          />
          <img
            class="frameMail"
            :src="`/static/img/home/top/frame/frame_${$i18n.locale}.png`"
            alt=""
          />
          <div class="buttons" :class="`${$i18n.locale}_buttons`">
            <div
              class="listDownloads"
              v-for="(item, i) in pcDownloadButtons"
              :class="[
                `${$i18n.locale}_download_${i}`,
                `${$i18n.locale}_download_${item.className}`,
                `${item.className}`,
              ]"
              :key="i"
            >
              <a :href="item.href">
                <img class="frame" :src="item.frame" />
              </a>
              <img
                v-if="item.className === 'to_shop_btn'"
                class="to_shop_label"
                :src="`/static/img/home/top/to_shop/to_shop_label_${$i18n.locale}.png?v=20240419`"
                alt=""
              />
            </div>
          </div>
        </div>
        <div class="list hidden-md-and-up">
          <img class="buttonBg" src="/static/img/home/top/frame_pm_bg.png" />
          <div class="buttons" :class="`${$i18n.locale}_buttons ${btnsClass}`">
            <img
              class="mobileFrameMail"
              :class="{ ios: isIOS }"
              :src="`/static/img/home/top/frame/frame_${$i18n.locale}.png`"
              alt=""
            />
            <div
              v-for="(item, i) in mobileDownloadButtons"
              class="listDownloads"
              :class="[
                `${$i18n.locale}_download_${i}`,
                `${$i18n.locale}_download_${item.className}`,
                `${item.className}`,
                { ios: isIOS },
              ]"
              :key="i"
            >
              <a :href="item.href">
                <img class="frame" :src="item.frame" />
              </a>
              <img
                v-if="item.className === 'to_shop_btn'"
                class="to_shop_label"
                :src="`/static/img/home/top/to_shop/to_shop_label_${$i18n.locale}.png?v=20240419`"
                alt=""
              />
            </div>
          </div>
        </div>
      </v-container>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  computed: {
    btnsClass() {
      return this.isIOS ? "ios_btns" : "android_btns";
    },
    toShopBtn() {
      return {
        frame:
          "/static/img/home/top/to_shop/btn_to_shop_" +
          this.$store.state.language +
          ".png",
        href: "/riichicitystore",
        className: "to_shop_btn",
      };
    },
    windows() {
      return {
        frame:
          "/static/img/home/top/win/win-" + this.$store.state.language + ".png",
        href: this.$store.state.downLink.Windows,
        className: "windows",
      };
    },
    mac() {
      return {
        frame:
          "/static/img/home/top/mac/mac-" + this.$store.state.language + ".png",
        href: this.$store.state.downLink.Mac,
        className: "mac",
      };
    },
    linux() {
      return {
        frame: "/static/img/home/top/linux/linux-" + this.$i18n.locale + ".png",
        href: this.$store.state.downLink.Linux,
        className: "linux",
      };
    },
    apk() {
      return {
        frame:
          "/static/img/home/top/apk/apk-" + this.$store.state.language + ".png",
        href: this.$store.state.downLink.APK,
        className: "apk",
      };
    },
    apple() {
      return {
        frame:
          "/static/img/home/top/apple/app_store_" +
          this.$store.state.language +
          ".png",
        href: this.$store.state.downLink.Apple,
        className: "apple",
      };
    },
    google() {
      return {
        frame:
          "/static/img/home/top/google/google-play_" +
          this.$store.state.language +
          ".png?t=20240827",
        href: this.$store.state.downLink.Google,
        className: "google",
      };
    },
    steam() {
      return {
        frame: "/static/img/home/top/steam-btn.png",
        href: this.$store.state.downLink.Steam,
        className: "steam",
      };
    },
    stove() {
      return {
        frame: "/static/img/home/top/stove/stove.jpg",
        href: this.$store.state.downLink.Stove,
        className: "stove",
      };
    },
    isIOS() {
      console.log(
        "window?.navigator?.userAgent:",
        window?.navigator?.userAgent
      );
      return (
        window?.navigator?.userAgent &&
        /(iP(ad|hone|od))|Mac/.test(window.navigator.userAgent)
      );
    },
    pcDownloadButtons() {
      if (this.$store.state.language === "kr") {
        // 韩文隐藏Google下载按钮
        return [
          this.toShopBtn,
          this.google,
          this.apple,
          this.steam,
          this.stove,
          this.windows,
          this.mac,
          // this.linux,
          this.apk,
        ];
      } else {
        return [
          this.toShopBtn,
          this.apple,
          this.google,
          this.steam,
          this.windows,
          this.mac,
          this.linux,
          this.apk,
        ];
      }
    },
    mobileDownloadButtons() {
      if (this.isIOS) {
        if (this.$store.state.language === "kr") {
          return [this.toShopBtn, this.apple, this.stove];
        } else {
          return [this.toShopBtn, this.apple];
        }
      } else {
        if (this.$store.state.language === "kr") {
          // 韩文隐藏Google下载按钮
          return [this.toShopBtn, this.google, this.apk, this.stove];
        } else {
          return [this.toShopBtn, this.google, this.apk];
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@keyframes sheen {
  1% {
  }
  33% {
    transform: rotateZ(60deg) translate(1em, -9em);
  }
  100% {
    transform: rotateZ(60deg) translate(1em, -9em);
  }
}
.topic {
  display: inline-flex;
  position: relative;
  justify-content: center;
  margin: 400px 0 0px 0;
  width: 700px;
  height: 130px;
  img {
    width: 100%;
    height: auto;
  }
  .left {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
    margin-right: 30px;
  }
  .right {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
    margin-left: 30px;
  }
}
.downloadButton {
  .container {
    max-width: 1200px;
    display: flex;
    justify-content: center;
    padding: 0px;
  }
  .button {
    position: relative;
    width: 400px;
    text-align: center;
    img {
      display: block;
      width: 100%;
      height: auto;
    }
    .name {
      position: absolute;
      color: #3e1d05;
      bottom: 25%;
      font-size: 36px;
      left: 65px;
      z-index: 4;
    }
  }
  .list {
    margin: 0 auto;
    padding-top: 0px;
    position: relative;
    z-index: 4;
    .buttonBg {
      width: 100%;
      height: 170px;
    }
    .frameMail {
      display: block;
      width: 25%;
      position: absolute;
      bottom: 22%;
      left: 2%;
    }
    .buttons {
      position: absolute;
      width: 70%;
      right: 2%;
      bottom: 6%;
      z-index: 6;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      .listDownloads {
        display: inline-block;
        margin-right: 15px;
        .frame {
          width: 100%;
          margin-bottom: 10px;
        }
        a {
          display: block;
        }
        &.to_shop_btn {
          position: relative;
          a {
            display: block;
            position: relative;
            overflow: hidden;
            &::after {
              content: "";
              position: absolute;
              top: -50%;
              right: -50%;
              bottom: -50%;
              left: -50%;
              background: linear-gradient(
                to bottom,
                rgba(229, 172, 142, 0),
                rgba(255, 255, 255, 0.5) 50%,
                rgba(229, 172, 142, 0)
              );
              transform: rotateZ(60deg) translate(-5em, 7.5em);
            }
            &::after {
              animation: sheen 3s infinite;
            }
          }
          .to_shop_label {
            position: absolute;
            width: 108px;
            top: -16px;
            right: -5px;
          }
        }
      }
      // .kr_download_google {
      //   visibility: hidden;
      // }
    }
    .kr_buttons {
      left: 30%;
    }
  }
}
@media only screen and (max-width: 1200px) {
  .downloadButton {
    .list {
      .buttons {
        bottom: 10%;
        .listDownloads {
          .frame {
            width: 100%;
            margin-right: 15px;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 960px) {
  .topic {
    margin-top: 350px;
    width: 500px;
    height: 300px;
  }
  .downloadButton {
    .list {
      .buttonBg {
        height: auto;
      }
      .buttons {
        display: flex;
        flex-direction: column;
        left: 50%;
        transform: translate(-50%, 0);
        margin-top: 20px;
        padding: 20px 20px;
        &.kr_buttons {
          &.android_btns {
            bottom: 0;
          }
          .mobileFrameMail {
            margin-bottom: 20px;
            &.ios {
              margin-bottom: 50px;
            }
            &.stove {
              .frame {
                margin-bottom: 0;
              }
            }
          }
          .listDownloads {
            &.apple {
              margin-top: 0px;
            }
          }
        }
        .mobileFrameMail {
          width: 100%;
          margin-bottom: 50px;
          &.ios {
            margin-bottom: 80px;
          }
        }
        .listDownloads {
          margin-right: 0;
          .frame {
            max-width: 300px;
            margin-bottom: 10px;
            margin-right: 0px;
          }
          &.to_shop_btn {
            &.ios {
              margin-bottom: 0px;
            }
          }
          &.apple {
            margin-bottom: 0px;
            margin-top: 30px;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 600px) {
  .topic {
    margin: 140px 0 0 0;
    width: 400px;
    height: 200px;
  }
  .downloadButton {
    .button {
      margin-top: 5px;
    }
  }
}
@media only screen and (max-width: 450px) {
  .topic {
    width: 350px;
    height: 80px;
    margin-top: 140px;
    img {
      &.mobileTopic {
        height: 200px;
      }
    }
  }
  .downloadButton {
    .button {
      .name {
        font-size: 28px;
      }
    }
    .list {
      .buttons {
        .listDownloads {
          .frame {
            margin-bottom: 10px;
          }
        }
      }
    }
  }
}
</style>
