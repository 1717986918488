export default {
  data() {
    return {
      isBan: null,
      requestCount: 10,
      isFirstRender: true,
    };
  },
  mounted() {
    this.getWebsiteInfo();
  },
  methods: {
    async getWebsiteInfo() {
      try {
        const res = await this.getWebsiteInfoReq();
        if (res.code === 0) {
          // 禁用韩国地区ip
          const isBan = res.data.area === "kr";
          this.setBan(isBan);
          // 设置下载按钮链接
          this.$store.commit("setDownload", res.data.downloadInfo);
          // 设置时间戳
          this.$store.commit("setTimeStamp", res.data.millisecond);
        } else {
          this.setBan(false);
          this.reqAgain();
        }
      } catch (error) {
        cconsole.log(error);
        this.setBan(false);
        this.reqAgain();
      }
    },
    // 当请求失败时，或返回code错误时重复请求
    reqAgain() {
      if (this.requestCount <= 0) return;
      this.getWebsiteInfo();
      this.requestCount--;
    },
    // 设置是否禁用页面
    setBan(isBan) {
      // 防止多次请求
      if (!this.isFirstRender) return;
      this.isFirstRender = false;
      this.isBan = isBan;
    },
    // 获取ip信息
    getWebsiteInfoReq() {
      const baseUrl = process.env.VUE_APP_BASE_URL;
      return new Promise((resolve) => {
        fetch(`${baseUrl}/store/webPage`, { method: "POST" })
          .then(function (res) {
            resolve(res.json());
          })
          .catch(function (err) {
            resolve({ code: 1 });
          });
      });
    },
  },
};
