export default {
  data() {
    return {
      isScreenshot: false, // 是否截图---活动截图用
    };
  },
  computed: {
    isShowEvent() {
     /*  const startDate = new Date(2024, 8, 29, 12, 0, 0); // 东八区2024年5月14日12点---测试用
      // const startDate = new Date(2024, 5, 14, 12, 0, 0); // 东八区2024年6月14日12点
      const endDate = new Date(2024, 11, 29, 22, 59, 59); // 东八区2024年7月30日22点59分59秒

      const startDateStamp = startDate.getTime();
      const endDateStamp = endDate.getTime();
      const now = this.$store.state.timeStamp;
      return now >= startDateStamp && now <= endDateStamp; */
      return false;
    },
    // logoSrc() {
    //   return require(`@/assets/image/home/main_bg/logo_${this.$store.state.language}.png`);
    // },
  },
};
