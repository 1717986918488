<template>
  <!-- <div>
    <NotFound v-if="isBan === true"></NotFound>
    <v-app v-else-if="isBan === false">
      <router-view></router-view>
    </v-app>
  </div> -->
  <v-app>
    <router-view></router-view>
  </v-app>
</template>

<script>
// import NotFound from "./components/NotFound.vue";
import { metaFunction } from "./configs/meta";
import ban from "@/mixins/ban";
export default {
  name: "App",
  // components: {
  //   NotFound,
  // },
  mixins: [ban],
  metaInfo() {
    return {
      title: metaFunction(this.$i18n.locale).title,
      meta: [
        {
          name: "description",
          content: metaFunction(this.$i18n.locale).description,
        },
        { name: "keywords", content: metaFunction(this.$i18n.locale).keywords },
        {
          property: "og:image",
          content:
            "https://www.mahjong-jp.com/static/img/common/happyWoodsLogo.png",
        },
      ],
    };
  },
  data() {
    return {};
  },
  mounted() {
    // this.init();
  },
  methods: {
    init() {
      this.$store.dispatch("getActivityList");
    },
  },
};
</script>
<style lang="scss">
html,
body {
  padding: 0;
  margin: 0;
  width: 100%;
  position: relative;
  font-family: "Open Sans", Helvetica, "STHeiti STXihei", "Microsoft YaHei",
    Tohoma, Arial, sans-serif;
  background-color: #fff;
  overflow-y: auto;
  overflow-x: hidden;
}
</style>
