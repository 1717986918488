import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import VideoPlayer from 'vue-video-player'
import VueGtag from 'vue-gtag'
import store from './store'
import i18n from './locales'
import Meta from 'vue-meta'
import './assets/main.css'
import '@mdi/font/css/materialdesignicons.css'

require('video.js/dist/video-js.css')
require('vue-video-player/src/custom-theme.css')

Vue.use(VideoPlayer)
Vue.use(Meta)

Vue.config.productionTip = false


Vue.use(VueGtag, {
  config: {
    id: 'G-XTDYKF0N8Q',
    pageTrackerTemplate (to) {
      return {
        page_title: 'Mahjong-jp',
        page_path: to.path
      }
    }
  }
}, router)

// router.beforeEach((to, from, next) => {
//   if(to.meta.metaInfo) {
//     store.commit("CHANGE_META_INFO", to.meta.metaInfo)
//   }
//   next()
// })
new Vue({
  vuetify,
  router,
  store,
  i18n,
  // metaInfo() {
  //   return {
  //     title: this.$store.state.metaInfo.title,
  //     meta:[
  //       {
  //         name: "keywords",
  //         content: this.$store.state.metaInfo.keywords
  //       },
  //       {
  //         name: "description",
  //         content: this.$store.state.metaInfo.description
  //       }
  //     ]
  //   }
  // },
  render: h => h(App)
}).$mount('#app')
