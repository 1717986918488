<template>
  <div>
    <div class="fixed">
      <root-header></root-header>
    </div>

 

    <div class="main">
      <keep-alive>
        <router-view v-if="$route.meta.keepAlive"> </router-view>
      </keep-alive>

      <router-view v-if="!$route.meta.keepAlive"> </router-view>
    </div>
    <root-footer></root-footer>
  </div>
</template>

<script>
import rootHeader from "@/components/RootHeader.vue";
import rootFooter from "@/components/RootFooter.vue";

export default {
  data() {
    return {

    };
  },
  components: {
    rootHeader,
    rootFooter,
  },
  
};
</script>

<style lang="scss">
.center-aligned {
  text-align: center;
}
.center-margin {
  margin: 0 auto;
}
.right-floated {
  float: right;
}
// .container {
//   max-width: 1300px;
//   margin: 0 auto;
// }
.inline-block {
  display: inline-block;
}
</style>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.fixed {
  position: relative;
  width: 100%;
  height: 80px;
  top: 0px;
  left: 0px;
  z-index: 1900;
}
.main {
  position: relative;
}
</style>
