<template>
  <v-lazy
    :min-height="200"
    :options="{ threshold: 0.5 }"
    transition="fade-transition"
  >
    <div class="BottomRole">
      <a
        v-if="isRole15"
        class="switch_role_pc"
        href="javascript:;"
        @click="switchRole"
      >
        <img
          :src="`/static/img/roles/role15/switch_icon_${roleType}_${lang}.png`"
          alt=""
        />
      </a>
      <div class="role_img_pc" :class="roleType">
        <div class="img_wrap">
          <template v-if="isRole15">
            <img
              v-show="roleType === 'day'"
              :src="`/static/img/roles/gif/role15_day.gif`"
              alt=""
            />
            <img
              v-show="roleType === 'night'"
              :src="`/static/img/roles/gif/role15_night.gif`"
              alt=""
            />
          </template>
          <template v-else>
            <img :src="roleInfo.mainImg" alt="" />
            <!-- <MyImg :src="roleInfo.mainImg"> </MyImg> -->
          </template>
        </div>
      </div>
      <div class="role_info">
        <div class="name_section">
          <div class="name" :class="lang">{{ $t(roleInfo.name) }}</div>
          <div class="nickname" :class="{ long_en_name: long_en_name }">
            {{ $t(roleInfo.nickname) }}
          </div>
        </div>
        <div class="audio_row">
          <a
            v-if="isRole15"
            class="switch_role_mobile"
            href="javascript:;"
            @click="switchRole"
          >
            <img
              :src="`/static/img/roles/role15/switch_icon_${roleType}_${lang}.png`"
              alt=""
            />
          </a>
          <span>{{ $t(roleInfo.cvname) }}</span>
          <a href="javascript:;" @click="handlePlayAudio">
            <img src="/static/img/home/bottom/trumpet.png" alt="" />
          </a>
        </div>
        <div class="role_img_mobile" :class="roleType">
          <div class="img_wrap">
            <img
              v-if="isRole15"
              :src="`/static/img/roles/gif/role15_${roleType}.gif`"
              alt=""
            />
            <img v-else :src="roleInfo.mainImg" alt="" />
          </div>
        </div>
        <div class="desc">{{ $t(roleInfo.description) }}</div>
        <div class="expression">
          <div class="img_wrap" v-for="item in expressionList" :key="item">
            <img :src="item" alt="" />
          </div>
        </div>
        <img
          class="expression_underline"
          src="/static/img/home/bottom/gifUnderline.png"
        />
        <div class="detail_btn">
          <router-link to="/characters">
            <p>{{ $t("home.p16") }}</p>
          </router-link>
        </div>
      </div>
    </div>
  </v-lazy>
</template>
<script>
import { rolesList } from "@/configs/characters.js";
// import MyImg from "@/components/MyImg.vue";
export default {
  props: {
    roleId: {
      type: String,
    },
  },
  components: {
    // MyImg,
  },
  data() {
    return {
      roleType: "day",
      audio: "",
      isPlaying: false,
      nextAudioSrc: "",
    };
  },
  computed: {
    long_en_name() {
      return (
        ["roles05", "roles06", "roles21"].includes(this.roleId) &&
        this.lang === "en"
      );
    },
    lang() {
      return this.$i18n.locale;
    },
    roleInfo() {
      return rolesList.find(({ id }) => id === this.roleId) || {};
    },
    expressionList() {
      if (!this.isRole15) return this.roleInfo.expression;
      if (this.roleType === "day") return this.roleInfo.expressionDay;
      return this.roleInfo.expressionNight;
    },
    audioList() {
      let audio;
      if (this.isRole15) {
        if (this.roleType === "day") {
          audio = this.roleInfo.voiceDay;
        } else {
          audio = this.roleInfo.voiceNight;
        }
      } else {
        audio = this.roleInfo.voice;
      }
      return audio.map((item) => {
        return `/static/audio/${item}`;
      });
    },
    isRole15() {
      return this.roleInfo.id === "roles15";
    },
  },
  mounted() {
    if (!this.roleInfo.id) this.$router.push("/");
    this.audio = new Audio();
    this.audio.addEventListener("ended", this.audioEnded);
  },
  beforeDestroy() {
    this.audio.pause();
    this.audio.removeEventListener("ended", this.audioEnded);
  },
  watch: {
    $route() {
      this.audio.pause();
      this.audio.removeEventListener("ended", this.audioEnded);
    },
  },
  methods: {
    audioEnded() {
      this.isPlaying = false;
      if (this.nextAudioSrc) {
        this.audio.src = this.nextAudioSrc;
        this.nextAudioSrc = null;
        this.audio.play();
        this.isPlaying = true;
      }
    },
    handlePlayAudio() {
      if (this.isPlaying && this.nextAudioSrc) return;
      const randomIndex = Math.floor(Math.random() * this.audioList.length);
      const nextAudioSrc = this.audioList[randomIndex];
      if (!this.isPlaying) {
        this.audio.src = nextAudioSrc;
        this.audio.play();
        this.isPlaying = true;
      } else {
        this.nextAudioSrc = nextAudioSrc;
      }
    },
    switchRole() {
      this.audio.pause();
      this.nextAudioSrc = null;
      this.isPlaying = false;
      if (this.roleType === "day") {
        this.roleType = "night";
      } else {
        this.roleType = "day";
      }
    },
  },
};
</script>
<style lang="scss" scoped>
img {
  max-width: 100%;
}
a {
  text-decoration: none;
}
.BottomRole {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  box-sizing: border-box;
  .switch_role_pc {
    display: block;
    position: absolute;
    left: 50%;
    top: 80px;
    z-index: 2;
    transform: translateX(-50%);
    width: 80px;
  }

  .role_img_pc {
    display: flex;
    flex: 1;
    flex-shrink: 0;
    justify-content: center;
    height: 90vh;
    padding-top: 80px;
    margin-right: 20px;
    img {
      max-height: 100%;
    }
    &.night {
      height: auto;
      img {
        width: 830px;
      }
    }
  }
  .role_info {
    width: 780px;
    padding-top: 38px;
    text-align: left;
    .name_section {
      width: 590px;
      height: 230px;
      background-image: url("/static/img/home/bottom/roleName.png");
      background-size: cover;
      background-repeat: no-repeat;
      text-align: center;
      .name {
        padding: 40px 0 0 0;
        font-size: 5rem;
        color: #ffffff;
        font-weight: 900;
        text-shadow: 0px 0px 20px #ff6098, 0px 0px 20px #ff6098;
        margin-bottom: 15px;
        &.en,
        &.kr {
          font-size: 4rem;
          margin-bottom: 38px;
        }
      }
      .nickname {
        height: 50px;
        line-height: 50px;
        margin-right: 130px;
        font-size: 2rem;
        color: #ffe4f8;
        margin-left: 3px;
        &.long_en_name {
          font-size: 24px;
        }
      }
    }
    .audio_row {
      display: flex;
      justify-content: flex-end;
      margin-top: -10px;
      margin-bottom: 30px;
      .switch_role_mobile {
        display: none;
      }
      > span {
        font-size: 1.625rem;
        line-height: 50px;
        margin: 0 10px 0 0;
        color: #ffffff;
      }
      a {
        display: block;
        img {
          display: block;
          width: 50px;
        }
      }
    }
    .role_img_mobile {
      display: none;
    }
    .desc {
      width: 582px;
      min-height: 200px;
      color: #c8c2fe;
      text-align: left;
      font-size: 13px;
      font-weight: 500;
      padding: 15px 20px 10px 20px;
      background-color: rgba($color: #000000, $alpha: 0.2);
      margin-bottom: 30px;
    }
    .expression {
      display: flex;
      width: 582px;
      min-height: 180px;
      .img_wrap {
        &:nth-child(2) {
          margin: 0 30px;
        }
      }
    }
    .expression_underline {
      margin-bottom: 45px;
    }
    .detail_btn {
      display: flex;
      justify-content: center;
      width: 582px;
      a {
        display: block;
        width: 300px;
        height: 75px;
        line-height: 75px;
        background-image: url("/static/img/home/bottom/roleButton.png");
        background-repeat: no-repeat;
        background-size: cover;
        color: #3e1d05;
        font-size: 30px;
        font-weight: 900;
        text-align: center;
      }
    }
  }
}

@media only screen and (max-width: 1600px) {
  .BottomRole {
    padding-right: 10px;
    .role_info {
      width: 500px;
      .name_section {
        width: 500px;
        height: 213px;
        .name {
          margin-bottom: 2px;
          &.en {
            margin-bottom: 48px;
            font-size: 3rem;
          }
          &.kr {
            font-size: 3rem;
            margin-bottom: 50px;
          }
        }
        .nickname {
          width: 426px;
          &.long_en_name {
            font-size: 22px;
          }
        }
      }
      .audio_row {
        margin-top: 15px;
        margin-right: 100px;
        margin-bottom: 60px;
      }
      .desc {
        width: 500px;
      }
      .expression {
        width: 500px;
      }
    }
  }
}
@media only screen and (max-width: 1200px) {
  .BottomRole {
    .switch_role_pc {
      left: 40%;
    }
  }
}
@media only screen and (max-width: 980px) {
  .BottomRole {
    justify-content: center;
    .switch_role_pc {
      display: none;
    }
    .role_img_pc {
      display: none;
    }
    .role_info {
      padding-top: 10px;
      .name_section {
        .name {
          font-size: 3rem;
          padding-top: 50px;
          margin-bottom: 40px;
          &.en {
            margin-bottom: 38px;
          }
          &.kr {
            font-size: 2.8rem;
            margin-bottom: 45px;
            // margin-bottom: 15px;
          }
        }
        // .nickname {
        // }
      }
      .audio_row {
        position: relative;
        width: 100%;
        margin-bottom: 20px;
        .switch_role_mobile {
          display: block !important;
          position: absolute;
          left: 90px;
        }
      }
      .role_img_mobile {
        display: flex !important;
        justify-content: center;
        width: 100%;
        max-height: 80vh;
        margin-bottom: 40px;
        .img_wrap {
          text-align: center;
          img {
            max-height: 100%;
          }
        }
        &.night {
          width: 100%;
          .img_wrap {
            width: 100%;
          }
        }
      }
      .detail_btn {
        width: 100%;
      }
    }
  }
}
@media only screen and (max-width: 520px) {
  .BottomRole {
    .role_info {
      // width: 300px;
      width: 100%;
      padding-left: 20px;
      padding-right: 10px;
      margin: 0 auto;
      .name_section {
        width: 300px;
        height: 128px;
        margin: 0 auto;
        .name {
          font-size: 3rem;
          padding-top: 0;
          margin-bottom: 25px;
          &.en,
          &.kr {
            font-size: 2rem;
            margin-bottom: 49px;
          }
        }
        .nickname {
          width: 260px;
          height: 28px;
          line-height: 28px;
          font-size: 1.2rem;
          &.long_en_name {
            font-size: 14px;
          }
        }
      }
      .audio_row {
        width: 100%;
        margin-left: 0;
        margin-right: 0;
        padding: 0;
        .switch_role_mobile {
          left: 56px;
        }
        > span {
          font-size: 1rem;
        }
      }
      .desc {
        width: 100%;
      }
      .expression {
        width: 100%;
        min-height: auto;
      }
      .detail_btn {
        width: 100%;
      }
    }
  }
}
</style>
