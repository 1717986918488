var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"TopBanner"},[_c('div',{staticClass:"topic"},[_c('img',{staticClass:"hidden-sm-and-down",attrs:{"src":("/static/img/home/top/topicSentence_" + (_vm.$i18n.locale) + ".png?t=20220620")}}),_c('img',{staticClass:"hidden-md-and-up mobileTopic",attrs:{"src":("/static/img/home/top/mobileTopic_" + (_vm.$i18n.locale) + ".png?t=20220620")}})]),_c('div',{staticClass:"downloadButton"},[_c('v-container',[_c('div',{staticClass:"list hidden-sm-and-down"},[_c('img',{staticClass:"buttonBg",attrs:{"src":"/static/img/home/top/frame_bg.png?v=20221028","alt":""}}),_c('img',{staticClass:"frameMail",attrs:{"src":("/static/img/home/top/frame/frame_" + (_vm.$i18n.locale) + ".png"),"alt":""}}),_c('div',{staticClass:"buttons",class:((_vm.$i18n.locale) + "_buttons")},_vm._l((_vm.pcDownloadButtons),function(item,i){return _c('div',{key:i,staticClass:"listDownloads",class:[
              ((_vm.$i18n.locale) + "_download_" + i),
              ((_vm.$i18n.locale) + "_download_" + (item.className)),
              ("" + (item.className)) ]},[_c('a',{attrs:{"href":item.href}},[_c('img',{staticClass:"frame",attrs:{"src":item.frame}})]),(item.className === 'to_shop_btn')?_c('img',{staticClass:"to_shop_label",attrs:{"src":("/static/img/home/top/to_shop/to_shop_label_" + (_vm.$i18n.locale) + ".png?v=20240419"),"alt":""}}):_vm._e()])}),0)]),_c('div',{staticClass:"list hidden-md-and-up"},[_c('img',{staticClass:"buttonBg",attrs:{"src":"/static/img/home/top/frame_pm_bg.png"}}),_c('div',{staticClass:"buttons",class:((_vm.$i18n.locale) + "_buttons " + _vm.btnsClass)},[_c('img',{staticClass:"mobileFrameMail",class:{ ios: _vm.isIOS },attrs:{"src":("/static/img/home/top/frame/frame_" + (_vm.$i18n.locale) + ".png"),"alt":""}}),_vm._l((_vm.mobileDownloadButtons),function(item,i){return _c('div',{key:i,staticClass:"listDownloads",class:[
              ((_vm.$i18n.locale) + "_download_" + i),
              ((_vm.$i18n.locale) + "_download_" + (item.className)),
              ("" + (item.className)),
              { ios: _vm.isIOS } ]},[_c('a',{attrs:{"href":item.href}},[_c('img',{staticClass:"frame",attrs:{"src":item.frame}})]),(item.className === 'to_shop_btn')?_c('img',{staticClass:"to_shop_label",attrs:{"src":("/static/img/home/top/to_shop/to_shop_label_" + (_vm.$i18n.locale) + ".png?v=20240419"),"alt":""}}):_vm._e()])})],2)])])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }