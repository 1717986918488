<template>
  <v-container>
    <v-lazy :min-height="200" :options="{ threshold: 0.5 }" transition="fade-transition">
      <div class="middle">
        <div class="middle-left">
          <img src="/static/img/home/middle/newsImg.jpeg?v=20211203" alt="" />
        </div>
        <div class="middle-right">
          <div class="background">
            <div class="title">
              <div
                class="title-item"
                v-for="(items, index) in titleItem"
                :key="index"
                :class="phase === items.phase ? 'active' : ''"
                @click="handleChange(items)"
              >
                {{ translate(items.name) }}
              </div>
            </div>
            <div class="message">
              <router-link class="link" v-for="(con, y) in listItem" :key="y" :to="con.href">
                <div class="cont">
                  <p class="text">
                    {{ translate(con.info) }}
                  </p>
                </div>
                <div class="date">{{ con.date }}</div>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </v-lazy>
  </v-container>
</template>

<script>
import { getNewList } from "@/api/news";
import { blackboardContent } from "./data";
import { translate, deepClone } from "@/utils/utils";
import dayjs from "dayjs";
export default {
  data() {
    return {
      loading: false,
      phase: 1,
      line: false,
      listItem: [],
      titleItem: [],
      serverNewsList: [],
      phaseMap: {
        1: 2, // 游戏公告
        2: 4, // 维护咨询
        3: 4, // 更新情报
        4: 4, // 活动咨询
        5: 3, // 比赛
        6: 4, // 福利
      },
    };
  },
  watch: {
    "$i18n.locale"() {
      this.setTitleItem();
      const item = this.titleItem.find((item) => item.phase === this.phase);
      this.handleChange(item);
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    async init() {
      await this.getServerNewsist();
      this.setTitleItem();
      this.handleChange(this.titleItem[0]);
    },
    setTitleItem() {
      const lang = this.$i18n.locale;
      const localList = deepClone(blackboardContent(lang));
      // const serverList =
      this.serverNewsList.forEach((serverItem) => {
        console.log("lang:", lang);
        const { title, main_img, content } = serverItem[lang];
        // 内容齐全才展示
        if (!title || !main_img || !content) return;
        // 对应的本地数据
        const findIndex = localList.findIndex((localItem) => localItem.phase === this.phaseMap[serverItem.type]);
        let basePath = "news";
        if (serverItem.type === 5) {
          basePath = "tournament";
        } else if (serverItem.type === 6) {
          basePath = "special";
        }
        const token = this.$route.query.token;
        const params = token ? `?token=${token}` : "";
        const serverContent = {
          info: title,
          date: dayjs(serverItem.release_at * 1000).format("YYYY-MM-DD"),
          href: `/${basePath}/${serverItem.nkey}${params}`,
          line: true,
        };
        // 有对应分类项的数据
        if (findIndex !== -1) {
          localList[findIndex].contents.push(serverContent);
        }
        // 全部项的数据
        localList[0].contents.push(serverContent);
      });
      // 排序
      localList.forEach((item) => {
        item.contents.sort((a, b) => {
          return new Date(b.date) - new Date(a.date);
        });
      });
      // 截取前四个
      localList.forEach((item) => {
        item.contents = item.contents.slice(0, 4);
      });
      this.titleItem = localList;
    },
    handleChange(items) {
      this.phase = items.phase;
      this.listItem = items.contents;
    },
    async getServerNewsist() {
      try {
        this.loading = true;
        const res = await getNewList({
          limit: 99,
          skip: 1,
          show_home: 1,
          token: this.$route.query.token || undefined,
        });
        if (res.data.code !== 0) return;
        this.serverNewsList = res.data.data;
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    translate,
  },
};
</script>

<style scoped lang="scss">
.container {
  display: flex;
  justify-content: center;
  max-width: 1400px;
  padding: 0 21px;
  @media only screen and (min-width: 2000px) {
    margin-top: 0;
  }
}

.middle {
  position: relative;
  display: flex;
  padding: 10px;
  border: 3px solid #7d8dfd;
  border-bottom: 15px solid #7d8dfd;
  background-color: rgba(235, 243, 245, 0.7);
  z-index: 6;
  &-left {
    width: auto;
    max-width: 800px;
    height: 360px;
    padding-right: 20px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  &-right {
    width: 550px;
    height: 360px;
    // margin-left: 10px;
    .background {
      background: url("/static/img/home/middle/middleRightBackground.png") no-repeat;
      background-size: cover;
      width: auto;
      height: 360px;
      .title {
        display: flex;
        justify-content: space-around;
        padding: 20px 15px 15px 15px;
        &-item {
          width: 120px;
          height: 40px;
          // margin-right: 6px;
          text-align: center;
          line-height: 40px;
          color: #504b93;
          font-size: 17px;
          font-weight: 900;
          // background-image: url("../assets/Ea.jpg");
          background-image: url("/static/img/home/middle/titleSelect_nor.png");
          background-size: 100% auto;
          background-repeat: no-repeat;
          cursor: pointer;
        }
        .active {
          color: #fff;
          -webkit-text-stroke: 1px #654a93;
          background-image: url("/static/img/home/middle/titleSelected.png");
          background-repeat: no-repeat;
          background-size: 100% auto;
          font-weight: 100;
        }
      }
      .message {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        padding-left: 10px;
        .link {
          display: flex;
          font-size: 17px;
          font-weight: 500;
          margin-top: 25px;
          color: #4d457c;
          border-bottom: 1px solid #7d8dfd;
          text-decoration: none;
          padding-left: 10px;
          margin-right: 2%;
          &:hover {
            color: #13bf1a;
          }
          .cont {
            max-width: 371px;
            text-align: left;
            .text {
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
          }
          .date {
            flex: 1;
            text-align: right;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1500px) {
  .middle {
    width: 80%;
    height: 338px;
    &-left {
      height: 320px;
      img {
        height: 280px;
      }
    }
    &-right {
      width: 600px;
      height: 320px;
      .background {
        height: 280px;
        .title {
          padding: 10px;
          &-item {
            width: 105px;
            height: 40px;
            font-size: 15px;
          }
        }
        .message {
          .link {
            // padding: 5px 0;
            font-size: 16px;
            margin-top: 3%;
            .cont {
              width: 70%;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1300px) {
  .container {
    padding: 10px;
  }
  .middle {
    width: 95%;
    height: 320px;
    &-left {
      height: 280px;
      img {
        height: 280px;
      }
    }
    &-right {
      height: 280px;
      width: 650px;
      .background {
        .title {
          padding: 10px 5px;
          &-item {
            width: 105px;
          }
        }
        .message {
          .link {
            font-size: 15px;
            margin-top: 2%;
            .cont {
              width: 75%;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1100px) {
  .middle {
    width: 95%;
    height: 100%;
    flex-direction: column;
    &-left {
      width: 100%;
      height: auto;
      margin-bottom: 10px;
      padding-right: 0;
    }
    &-right {
      width: 100%;
      height: auto;
      .background {
        height: 300px;
        .title {
          &-item {
            width: 105px;
            height: 40px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 980px) {
  .middle {
    width: 90%;
    &-left {
      padding-right: 0;
      img {
        height: 220px;
      }
    }
    &-right {
      .background {
        height: 270px;
        .title {
          flex-wrap: wrap;
          &-item {
            width: 105px;
            height: 40px;
            margin-bottom: 5px;
          }
        }
        .message {
          .link {
            font-size: 15px;
            .cont {
              width: 70%;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 772px) {
  .container {
    margin-left: 20px;
  }
  .middle {
    width: 90%;
    &-left {
      img {
        height: 220px;
      }
    }
    &-right {
      .background {
        height: 260px;
        .title {
          &-item {
            width: 90px;
            font-size: 12px;
          }
        }
        .message {
          .link {
            font-size: 13px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .middle {
    width: 90%;
    &-left {
      img {
        height: 155px;
      }
    }
    &-right {
      .background {
        height: 210px;
        .title {
          flex-wrap: nowrap;
          &-item {
            height: 30px;
            width: 80px;
            line-height: 30px;
            margin: 0 5px;
            font-size: 10px;
          }
        }
        .message {
          .link {
            font-size: 10px;
            .cont {
              width: 70%;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 450px) {
  .middle {
    width: 90%;
    &-left {
      img {
        height: 150px;
      }
    }
    &-right {
      .background {
        height: 210px;
        .message {
          .link {
            padding: 0;
            .cont {
              width: 70%;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 375px) {
  .middle {
    width: 80%;
    margin-left: 20px;
  }
}
</style>
