import axios from "axios";
axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;
// 请求拦截器
axios.interceptors.request.use(
  function (config) {
    // 在发送请求之前做些什么
    config.headers["Content-Type"] = "application/json;charset=utf-8";
    return config;
  },
  function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
  }
);
//响应拦截器
axios.interceptors.response.use(
  function (response) {
    // token过期
    if (response.data.code === 501) {
      loginState.isShowLoginTip = true;
      loginState.loginTipType = 2;
      localStorage.removeItem("userInfo");
      loginState.userInfo = {
        uid: "",
        nickname: "",
        email: "",
        token: "",
      };
    }
    return response;
  },
  function (error) {
    // 对响应错误做点什么
    return Promise.reject(error);
  }
);
export default axios;
