import axios from "axios";

export const getNewList = (data) => {
  let baseURL = "";
  if (process.env.NODE_ENV === "development") {
    baseURL = process.env.VUE_APP_RIICHICITY_URL;
  }
  return axios({
    baseURL: baseURL,
    method: "post",
    url: `/client/news_list`,
    data,
  });
};

export const getNewDetail = (data) => {
  let baseURL = "";
  if (process.env.NODE_ENV === "development") {
    baseURL = process.env.VUE_APP_RIICHICITY_URL;
  }
  return axios({
    baseURL: baseURL,
    method: "post",
    url: `/client/news_detail`,
    data,
  });
};
